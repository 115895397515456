<!-- 维权订单列表 -->
<template>
  <div>
    <van-nav-bar :title="$route.meta.name" left-arrow @click-left="$router.back()"/>
    <page-container class="nav-page-container">
      <div class="after-sale-list-container">
        <van-search
            v-model.trim="searchName"
            shape="round"
            show-action
            placeholder="请输入搜索关键词"
            @search="handleOnSearch"
        >
          <template #action>
            <div class="search-btn" @click="handleOnSearch">搜索</div>
          </template>
        </van-search>

        <page-container
            ref="pageContainer"
            class="order-list-scroller"
            :scroll-top.sync="scrollTop"
        >
          <!-- 下拉刷新 -->
          <van-pull-refresh
              v-model="refreshing"
              @refresh="onRefresh"
              style="position: relative;z-index: 1"
          >
            <template #loading>
              {{ pagination.pageNum < 2 ? '' : '加载中...' }}
            </template>

            <!-- 更多列表加载 -->
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
              <!-- 订单 -->
              <div
                  v-for="item of dataList"
                  class="order-info-item"
                  @click="handleOrderDetail(item)"
              >
                <div class="agency-container">
                  <span class="agency-name"><van-icon name="shop-o"/>{{ item.agencyName }}</span>
                  <div class="order-status-name">{{ getReturnStatusName(item) }}</div>
                </div>
                <div v-for="(ele,i) in item.linkGoodsList">
                  <goods-info
                      view-model="small"
                      :value="{
                  goodsImgSrc:  ele.tagIcon || ele.thumbUrl || item.thumbImageUrl,
                   goodsName: ele.goodsName,
                  goodsDesc: ele.brandName,
                  goodsPrice: item.amount,
                  tagTitle: ele.tagTitle,
                }"
                  >
                    <template #price-right>
                      ×{{ item.num }}
                    </template>
                  </goods-info>
                </div>
                <div class="btn-container">
                  <van-button
                      size="small" round plain color="#666"
                      @click.stop="handleCancelAdvice(item)"
                  >取消申诉
                  </van-button>
                  <van-button
                      v-if="getReturnStatus(item) === SHANG_PU_TONG_YI || getReturnStatus(item) === '08'"
                      size="small" round type="primary"
                      @click.stop="handleSendBack(item)"
                  >填写退货单号
                  </van-button>
                </div>
              </div>
            </van-list>
          </van-pull-refresh>

        </page-container>

      </div>
    </page-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { objectGetter } from '@/util';
import { getAllOrdersByPage } from '@/api/normalOrder';
import GoodsInfo from '@/components/GoodsInfo';
import { Toast } from 'vant';
import {
  RETURN_STATUS_MAP,
  CHUANG_JIAN_SHEN_QING,
  SHANG_PU_TONG_YI,
  SHANG_PU_JU_JUE,
  YONG_HU_FA_HUO,
  GONG_YING_SHANG_SHOU_HUO,
  GONG_YING_SHANG_JU_SHOU,
  DIAN_PU_JU_JUE_GONG_YING_SHANG_JU_JUE,
} from '@/const/afterSale';

export default {
  name: 'afterSaleList',
  components: { GoodsInfo },
  data() {
    return {
      searchName: '',
      searchForm: {
        goodsName: '',
        status: '06', // 维权订单
      },

      scrollTop: 0,
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      pagination: {
        // 分页参数
        pageNum: 0,
        pageSize: 10,
        total: 0,
      },

    };
  },
  computed: {
    ...mapState({
      storeRefreshing: state => state.afterSaleList.refreshing,
    }),
    // 维权售后退换货状态
    RETURN_STATUS_MAP() {
      return {
        ...RETURN_STATUS_MAP,
        [CHUANG_JIAN_SHEN_QING]: '商铺审核中',
        [SHANG_PU_TONG_YI]: '商铺已同意维权',
        [SHANG_PU_JU_JUE]: '维权失败',
        [YONG_HU_FA_HUO]: '供应商审核中',
        [GONG_YING_SHANG_SHOU_HUO]: '维权成功',
        [GONG_YING_SHANG_JU_SHOU]: '维权失败',
        [DIAN_PU_JU_JUE_GONG_YING_SHANG_JU_JUE]: '维权失败',
      };
    },
    SHANG_PU_TONG_YI() {
      return SHANG_PU_TONG_YI;
    }
  },
  created() {
    this.$watch('storeRefreshing', () => {
      if (!this.storeRefreshing)
        return;
      this.$store.commit('afterSaleList/setRefreshing', false);

      Object.assign(this, {
        searchName: '',
        searchForm: {
          goodsName: '',
          status: '06', // 维权订单
        },
        dataList: [],
        pagination: {
          // 分页参数
          pageNum: 0,
          pageSize: 10,
          total: 0,
        },
      });

      this.onRefresh();
    }, { immediate: true });
  },
  activated() {
    this.$refs.pageContainer.scrollToTop(this.scrollTop);
  },
  methods: {
    objectGetter,
    handleOnSearch() {
      this.searchForm.goodsName = this.searchName;
      this.onRefresh();
    },

    onLoad() {
      this.pagination.pageNum++;
      let {
        searchForm,
        pagination: { pageNum: current, pageSize: size },
      } = this;

      return getAllOrdersByPage({
        ...searchForm,
        current,
        size,
      }).then(data => {
        this.dataPageLoadingSuccess();
        let { records: dataList = [], total = 0 } = data || {};
        this.dataList = [...this.dataList, ...dataList];
        this.pagination.total = total || 0;
        if (this.dataList.length >= this.pagination.total || !dataList.length)
          this.finished = true;
      });
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.pagination.pageNum = 0;
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      return this.onLoad();
    },
    dataPageLoadingSuccess() {
      this.loading = false;
      if (this.refreshing) {
        this.dataList = [];
        this.refreshing = false;
      }
    },

    // 维权状态status get
    getReturnStatus(item) {
      return objectGetter(item, 'orderReturn.status');
    },
    // 维权状态status name get
    getReturnStatusName(item) {
      let status = this.getReturnStatus(item);
      return this.RETURN_STATUS_MAP[status] || '维权审核中';
    },

    // 订单详情
    handleOrderDetail(order) {
      this.$router.push({
        path: '/orderDetail',
        query: {
          orderId: order.id,
        },
      });
    },
    // 取消申诉
    handleCancelAdvice(order) {
      Toast('功能开发中...');
    },
    // 填写退货单号
    handleSendBack(order) {
      this.$store.commit('sendBack/setRefreshing', true);
      this.$router.push({
        path: '/sendBack',
        query: {
          orderReturnId: objectGetter(order, 'orderReturn.id')
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.after-sale-list-container {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  .search-btn {
    padding: 0 7px;
    color: @primary-color;
  }

  .order-list-scroller {
    top: @search-padding * 2 + @search-input-height;

    .order-info-item {
      margin: 10px;
      padding: 10px;
      border-radius: 15px;
      background-color: #fff;

      .agency-container {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 4px 0 14px 0;
        line-height: 1.2;
        font-size: 16px;
        color: #333;

        .agency-name {
          padding: 0 10px 0 0;

          .van-icon {
            margin-right: 8px;
            font-size: 18px;
          }
        }

        .order-status-name {
          flex-shrink: 0;
          font-size: 16px;
          color: @primary-color;
        }
      }

      .btn-container {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-items: center;
        box-sizing: border-box;
        margin-top: 20px;
        padding: 5px 0;

        button {
          padding: 0 12px;
          font-size: 14px;
          margin: 5px 0 5px 10px;
        }
      }
    }
  }
}
</style>
